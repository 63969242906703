import { CustomerId, CustomerDetails } from "./model";

import {
  SET_ACTIVE_CUSTOMER_DETAILS_ID,
  CLEAR_ACTIVE_CUSTOMER_DETAILS_ID,
  SHOW_STORED_CUSTOMER_DETAILS,
  FETCH_CUSTOMER_DETAILS_SUCCESS,
  FETCH_CUSTOMER_DETAILS_ERROR,
} from "../actions";

export const setActiveCustomerDetailsId = (customerId: CustomerId) => {
  return { type: SET_ACTIVE_CUSTOMER_DETAILS_ID, payload: customerId };
};

export const clearActiveCustomerDetailsId = () => {
  return { type: CLEAR_ACTIVE_CUSTOMER_DETAILS_ID };
};

export const showStoredCustomerDetails = () => {
  return { type: SHOW_STORED_CUSTOMER_DETAILS };
};

export const fetchCustomerDetailsSuccess = (results: CustomerDetails) => {
  return {
    type: FETCH_CUSTOMER_DETAILS_SUCCESS,
    payload: results,
  };
};

export const fetchCustomerDetailsError = (message: string) => {
  return { type: FETCH_CUSTOMER_DETAILS_ERROR, payload: message };
};
