import {
  UserFilterState,
  UserFilterAction,
  UserListingState,
  UserAction,
} from "./model";
import {
  SET_USER_FILTER,
  FETCH_USER_LISTING,
  FETCH_USER_LISTING_SUCCESS,
  FETCH_USER_LISTING_ERROR,
} from "../actions";

const INIT_USER_FILTER_STATE: UserFilterState = {
  currentPage: 1,
  query: "",
  role: "admin",
  // role: 'merchant',
  brandId: "",
  status: "",
  accessRightId: "",
  sortBy: "adminName",
  // sortBy: 'outletName',
  sortOrder: "ASC",
};

export const userFilterReducer = (
  state: UserFilterState = INIT_USER_FILTER_STATE,
  action: UserFilterAction
) => {
  switch (action.type) {
    case SET_USER_FILTER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const INIT_USER_STATE: UserListingState = {
  results: null,
  loading: false,
  error: "",
};

export const userListingReducer = (
  state: UserListingState = INIT_USER_STATE,
  action: UserAction
) => {
  switch (action.type) {
    case FETCH_USER_LISTING:
      return { ...state, loading: true, error: "" };
    case FETCH_USER_LISTING_SUCCESS:
      return { loading: false, error: "", results: { ...action.payload } };
    case FETCH_USER_LISTING_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
