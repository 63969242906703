import moment from "moment";
import {
  BalanceChangeFilterState,
  BalanceChangeFilterAction,
  BalanceChangeListingState,
  BalanceChangeAction,
} from "./model";
import {
  SET_BALANCE_CHANGE_FILTER,
  FETCH_BALANCE_CHANGE_LISTING,
  FETCH_BALANCE_CHANGE_LISTING_SUCCESS,
  FETCH_BALANCE_CHANGE_LISTING_ERROR,
} from "../actions";

const INIT_BALANCE_CHANGE_FILTER_STATE: BalanceChangeFilterState = {
  currentPage: 1,
  query: "",
  changeType: "",
  outlets: [],
  startDate: moment().subtract(1, "days").format("DD/MM/YYYY"),
  endDate: moment().format("DD/MM/YYYY"),
  sortBy: "balanceChangeDate",
  sortOrder: "DESC",
};

export const balanceChangeFilterReducer = (
  state: BalanceChangeFilterState = INIT_BALANCE_CHANGE_FILTER_STATE,
  action: BalanceChangeFilterAction
) => {
  switch (action.type) {
    case SET_BALANCE_CHANGE_FILTER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const INIT_BALANCE_CHANGE_STATE: BalanceChangeListingState = {
  results: null,
  loading: false,
  error: "",
};

export const balanceChangeListingReducer = (
  state: BalanceChangeListingState = INIT_BALANCE_CHANGE_STATE,
  action: BalanceChangeAction
) => {
  switch (action.type) {
    case FETCH_BALANCE_CHANGE_LISTING:
      return { ...state, loading: true, error: "" };
    case FETCH_BALANCE_CHANGE_LISTING_SUCCESS:
      return { loading: false, error: "", results: { ...action.payload } };
    case FETCH_BALANCE_CHANGE_LISTING_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
