import moment from "moment";
import { ReportFilterState, ReportFilterAction, ReportType } from "./model";
import { SET_REPORT_FILTER } from "../actions";

const INIT_REPORT_FILTER_STATE: ReportFilterState = {
  reportType: ReportType.TOP_UP_SUMMARY,
  brandId: "",
  outletId: "",
  startDate: moment().subtract(1, "days").format("DD/MM/YYYY"),
  endDate: moment().subtract(1, "days").format("DD/MM/YYYY"),
};

export const reportFilterReducer = (
  state: ReportFilterState = INIT_REPORT_FILTER_STATE,
  action: ReportFilterAction
) => {
  switch (action.type) {
    case SET_REPORT_FILTER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
