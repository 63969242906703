import {
  all,
  call,
  fork,
  put,
  takeEvery,
  SagaReturnType,
} from "redux-saga/effects";

import { FETCH_BALANCE_CHANGE_LISTING } from "../actions";

import {
  fetchBalanceChangeListingSuccess,
  fetchBalanceChangeListingError,
} from "./actions";

import { fetchBalanceChangeListAPI } from "../../APIs";

import { BalanceChangeListingReq, BalanceChangeListingResponse } from "./model";

import { RootState } from "../reducers";
import messageStrings from "../../helpers/messageStrings";

const fetchBalanceChangesAsync = async (
  filters: BalanceChangeListingReq
): Promise<BalanceChangeListingResponse> => {
  try {
    const response = await fetchBalanceChangeListAPI(filters);
    return response.data;
  } catch (err) {
    throw err;
  }
};

function* handleFetchBalanceChanges({
  payload,
}: {
  type: typeof FETCH_BALANCE_CHANGE_LISTING;
  payload: BalanceChangeListingReq;
}) {
  try {
    const results: SagaReturnType<typeof fetchBalanceChangesAsync> = yield call(
      fetchBalanceChangesAsync,
      payload
    );

    yield put(fetchBalanceChangeListingSuccess(results));
  } catch (error: any) {
    yield put(
      fetchBalanceChangeListingError(
        error.message || messageStrings.defaultError
      )
    );
  }
}

export function* watchFetchBalanceChangeListing() {
  yield takeEvery(FETCH_BALANCE_CHANGE_LISTING, handleFetchBalanceChanges);
}

export default function* rootSaga() {
  yield all([fork(watchFetchBalanceChangeListing)]);
}
