import {
  all,
  call,
  fork,
  put,
  takeEvery,
  SagaReturnType,
} from "redux-saga/effects";

import { FETCH_CUSTOMER_LISTING } from "../actions";

import {
  fetchCustomerListingSuccess,
  fetchCustomerListingError,
} from "./actions";

import { fetchCustomerListAPI } from "../../APIs";

import { CustomerFilterState, CustomerListingResponse } from "./model";

import messageStrings from "../../helpers/messageStrings";

const fetchCustomersAsync = async (
  filters: CustomerFilterState
): Promise<CustomerListingResponse> => {
  try {
    const response = await fetchCustomerListAPI(filters);
    return response.data;
  } catch (err) {
    throw err;
  }
};

function* handleFetchCustomers({
  payload,
}: {
  type: typeof FETCH_CUSTOMER_LISTING;
  payload: CustomerFilterState;
}) {
  try {
    const results: SagaReturnType<typeof fetchCustomersAsync> = yield call(
      fetchCustomersAsync,
      payload
    );

    yield put(fetchCustomerListingSuccess(results));
  } catch (error: any) {
    yield put(
      fetchCustomerListingError(error.message || messageStrings.defaultError)
    );
  }
}

export function* watchFetchCustomerListing() {
  yield takeEvery(FETCH_CUSTOMER_LISTING, handleFetchCustomers);
}

export default function* rootSaga() {
  yield all([fork(watchFetchCustomerListing)]);
}
