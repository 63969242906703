import {
  UnlinkedCardFilterState,
  UnlinkedCardFilterAction,
  UnlinkedCardListingState,
  UnlinkedCardAction,
  DeleteUnlinkedCardState,
  DeleteUnlinkedCardAction,
} from "./model";
import {
  SET_UNLINKED_CARD_FILTER,
  FETCH_UNLINKED_CARD_LISTING,
  FETCH_UNLINKED_CARD_LISTING_SUCCESS,
  FETCH_UNLINKED_CARD_LISTING_ERROR,
  ADD_CREATED_CARDS_TO_LISTING,
  SET_DELETE_UNLINKED_CARD,
  DELETE_UNLINKED_CARD_FROM_LISTING,
} from "../actions";

const INIT_UNLINKED_CARD_FILTER_STATE: UnlinkedCardFilterState = {
  query: "",
  sortBy: "dateAdded",
  sortOrder: "DESC",
};

export const unlinkedCardFilterReducer = (
  state: UnlinkedCardFilterState = INIT_UNLINKED_CARD_FILTER_STATE,
  action: UnlinkedCardFilterAction
) => {
  switch (action.type) {
    case SET_UNLINKED_CARD_FILTER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const INIT_UNLINKED_CARD_STATE: UnlinkedCardListingState = {
  results: null,
  loading: false,
  error: "",
};

export const unlinkedCardListingReducer = (
  state: UnlinkedCardListingState = INIT_UNLINKED_CARD_STATE,
  action: UnlinkedCardAction
) => {
  switch (action.type) {
    case FETCH_UNLINKED_CARD_LISTING:
      return { ...state, loading: true, error: "" };
    case FETCH_UNLINKED_CARD_LISTING_SUCCESS:
      return { loading: false, error: "", results: { ...action.payload } };
    case FETCH_UNLINKED_CARD_LISTING_ERROR:
      return { ...state, loading: false, error: action.payload };
    case ADD_CREATED_CARDS_TO_LISTING:
      let createdCards =
        action.payload && Array.isArray(action.payload) ? action.payload : [];
      let totalItemsCountAfterAdd =
        state.results && "totalItemsCount" in state.results
          ? state.results.totalItemsCount + createdCards.length
          : null;
      let itemsAfterAdd =
        state.results && state.results.items
          ? [...state.results.items, ...createdCards]
          : null;

      return {
        ...state,
        results: state.results
          ? { totalItemsCount: totalItemsCountAfterAdd, items: itemsAfterAdd }
          : null,
      };
    case DELETE_UNLINKED_CARD_FROM_LISTING:
      let cardId = action.payload;
      let itemsAfterDelete =
        state.results && Array.isArray(state.results.items)
          ? state.results.items.filter((item) => item.id !== cardId)
          : null;
      let totalItemsCountAfterDelete =
        state.results && "totalItemsCount" in state.results && itemsAfterDelete
          ? itemsAfterDelete.length
          : null;

      return {
        ...state,
        results: state.results
          ? {
              totalItemsCount: totalItemsCountAfterDelete,
              items: itemsAfterDelete,
            }
          : null,
      };
    default:
      return state;
  }
};

const INIT_DELETE_UNLINKED_CARD_STATE: DeleteUnlinkedCardState = null;

export const deleteUnlinkedCardReducer = (
  state: DeleteUnlinkedCardState = INIT_DELETE_UNLINKED_CARD_STATE,
  action: DeleteUnlinkedCardAction
) => {
  switch (action.type) {
    case SET_DELETE_UNLINKED_CARD:
      return action.payload;
    default:
      return state;
  }
};
