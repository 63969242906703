import {
  UnlinkedCardFilter,
  UnlinkedCardListing,
  UnlinkedCardItem,
  DeleteUnlinkedCardId,
  DeleteUnlinkedCardState,
} from "./model";

import {
  SET_UNLINKED_CARD_FILTER,
  FETCH_UNLINKED_CARD_LISTING,
  FETCH_UNLINKED_CARD_LISTING_SUCCESS,
  FETCH_UNLINKED_CARD_LISTING_ERROR,
  ADD_CREATED_CARDS_TO_LISTING,
  SET_DELETE_UNLINKED_CARD,
  DELETE_UNLINKED_CARD_FROM_LISTING,
} from "../actions";

export const setUnlinkedCardFilter = (
  newFiltersKeyValues: UnlinkedCardFilter
) => {
  return { type: SET_UNLINKED_CARD_FILTER, payload: newFiltersKeyValues };
};

export const fetchUnlinkedCardListing = (filters: { [key: string]: any }) => {
  return { type: FETCH_UNLINKED_CARD_LISTING, payload: filters };
};

export const fetchUnlinkedCardListingSuccess = (
  results: UnlinkedCardListing
) => {
  return { type: FETCH_UNLINKED_CARD_LISTING_SUCCESS, payload: results };
};

export const fetchUnlinkedCardListingError = (message: string) => {
  return { type: FETCH_UNLINKED_CARD_LISTING_ERROR, payload: message };
};

export const addCreatedCardsToListing = (createdCards: UnlinkedCardItem[]) => {
  return { type: ADD_CREATED_CARDS_TO_LISTING, payload: createdCards };
};

export const setDeleteUnlinkedCard = (cardOrEmpty: DeleteUnlinkedCardState) => {
  return { type: SET_DELETE_UNLINKED_CARD, payload: cardOrEmpty };
};

export const deleteUnlinkedCardFromListing = (cardId: DeleteUnlinkedCardId) => {
  return { type: DELETE_UNLINKED_CARD_FROM_LISTING, payload: cardId };
};
