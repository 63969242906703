import {
  all,
  call,
  fork,
  put,
  takeEvery,
  select,
  SagaReturnType,
} from "redux-saga/effects";
import moment from "moment";
import { SET_ACTIVE_CUSTOMER_DETAILS_ID } from "../actions";

import {
  showStoredCustomerDetails,
  fetchCustomerDetailsSuccess,
  fetchCustomerDetailsError,
} from "./actions";

import { CustomerDetails, CustomerId, CustomerDetailsResponse } from "./model";

import { RootState } from "../reducers";

import { fetchCustomerDetailsAPI } from "../../APIs";
import messageStrings from "../../helpers/messageStrings";

const fetchCustomerDetailsAsync = async (
  customerId: CustomerId
): Promise<CustomerDetailsResponse> => {
  try {
    const response = await fetchCustomerDetailsAPI(customerId);
    return response.data;
  } catch (err) {
    throw err;
  }
};

function getStoredCustomerDetails(state: RootState): CustomerDetails[] {
  return state.customerDetails.results;
}

function* showStoredOrFetchCustomerDetails({
  payload,
}: {
  type: typeof SET_ACTIVE_CUSTOMER_DETAILS_ID;
  payload: CustomerId;
}) {
  const storedCustomerDetails: SagaReturnType<typeof getStoredCustomerDetails> =
    yield select(getStoredCustomerDetails);

  const isStored = storedCustomerDetails.some(
    (customerDetails) => customerDetails.customerId === payload
  );

  if (isStored) {
    // No need to fetch from server, use from store
    yield put(showStoredCustomerDetails());
  } else {
    // Fetch from server and store
    try {
      const results: SagaReturnType<typeof fetchCustomerDetailsAsync> =
        yield call(fetchCustomerDetailsAsync, payload);

      let transactions = results.transaction || [];

      //Combine transactions with same orderId into one order
      if (transactions.length > 0) {
        let mergedOrders = transactions.reduce((orders, transaction) => {
          let newObj: { [key: string]: any } = { ...orders };

          if (!newObj[transaction.orderId]) {
            newObj[transaction.orderId] = {
              ...transaction,
              totalAmount: transaction.subTotal,
              customerPriorBalance: transaction.customerPriorBalance,
              items: [
                {
                  itemQuantity: transaction.itemQuantity,
                  itemDescription: transaction.itemDescription,
                  itemPrice: transaction.itemPrice,
                },
              ],
              itemQuantity: undefined,
              itemDescription: undefined,
              itemPrice: undefined,
            };
          } else {
            newObj[transaction.orderId].items.push({
              itemQuantity: transaction.itemQuantity,
              itemDescription: transaction.itemDescription,
              itemPrice: transaction.itemPrice,
            });
            console.log("before", newObj[transaction.orderId].totalAmount);
            newObj[transaction.orderId].totalAmount =
              newObj[transaction.orderId].totalAmount + transaction.subTotal;
            console.log("after", newObj[transaction.orderId].totalAmount);
            if (
              newObj[transaction.orderId].customerPriorBalance &&
              transaction.customerPriorBalance &&
              newObj[transaction.orderId].customerPriorBalance <
                transaction.customerPriorBalance
            ) {
              newObj[transaction.orderId].customerPriorBalance =
                transaction.customerPriorBalance;
            }
          }

          return newObj;
        }, {});

        transactions = Object.values(mergedOrders);
      }

      const balanceChanges = results.balanceChange || [];
      //Merge and sort transactions + balanceChanges into a timeline array
      const timeline = [...transactions, ...balanceChanges].sort(function (
        a,
        b
      ) {
        const mA = moment(
          "transactionDate" in a ? a.transactionDate : a.balanceChangeDate
        );
        const mB = moment(
          "transactionDate" in b ? b.transactionDate : b.balanceChangeDate
        );

        if (mA.isBefore(mB)) return 1;
        if (mA.isAfter(mB)) return -1;

        return 0;
      });

      yield put(
        fetchCustomerDetailsSuccess({
          customer: results.customer,
          card: results.card,
          timeline: timeline,
          customerId: payload,
        })
      );
    } catch (error: any) {
      yield put(
        fetchCustomerDetailsError(error.message || messageStrings.defaultError)
      );
    }
  }
}

export function* watchSetActiveCustomerDetailsId() {
  yield takeEvery(
    SET_ACTIVE_CUSTOMER_DETAILS_ID,
    showStoredOrFetchCustomerDetails
  );
}

export default function* rootSaga() {
  yield all([fork(watchSetActiveCustomerDetailsId)]);
}
