import { DropdownOptionsData } from "./model";

import {
  FETCH_DROPDOWN_OPTIONS,
  FETCH_DROPDOWN_OPTIONS_ERROR,
  FETCH_DROPDOWN_OPTIONS_SUCCESS,
} from "../actions";

export const fetchDropdownOptions = () => {
  return { type: FETCH_DROPDOWN_OPTIONS };
};

export const fetchDropdownOptionsSuccess = (data: DropdownOptionsData) => {
  return { type: FETCH_DROPDOWN_OPTIONS_SUCCESS, payload: data };
};

export const fetchDropdownOptionsError = (message: string) => {
  return { type: FETCH_DROPDOWN_OPTIONS_ERROR, payload: message };
};
