import {
  FETCH_DASHBOARD_DATA_ANALYTICS,
  FETCH_DASHBOARD_DATA_ANALYTICS_SUCCESS,
  FETCH_DASHBOARD_DATA_ANALYTICS_ERROR,
  SET_DASHBOARD_TAB,
  SET_DASHBOARD_BRAND_OPTION,
  LOGOUT_USER,
} from "../actions";

import { DashboardState, DashboardAction, DashboardTab } from "./model";

const INIT_DASHBOARD_STATE: DashboardState = {
  tab: DashboardTab.ORGANIZATION,
  brandOption: "",
  error: "",
  loading: false,
  data: null,
};

const dashboardReducer = (
  state: DashboardState = INIT_DASHBOARD_STATE,
  action: DashboardAction
) => {
  switch (action.type) {
    case SET_DASHBOARD_TAB:
      return { ...state, tab: action.payload };
    case SET_DASHBOARD_BRAND_OPTION:
      return { ...state, brandOption: action.payload };
    case LOGOUT_USER:
      //Reset dashboard for next user
      return { ...INIT_DASHBOARD_STATE };
    case FETCH_DASHBOARD_DATA_ANALYTICS:
      return { ...state, loading: true, error: "" };
    case FETCH_DASHBOARD_DATA_ANALYTICS_SUCCESS:
      return { ...state, data: action.payload, error: "", loading: false };

    case FETCH_DASHBOARD_DATA_ANALYTICS_ERROR:
      return { ...state, data: null, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default dashboardReducer;
