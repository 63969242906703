import {
  all,
  call,
  fork,
  put,
  takeEvery,
  SagaReturnType,
} from "redux-saga/effects";

import {
  fetchDropdownOptionsSuccess,
  fetchDropdownOptionsError,
} from "./actions";
import { DropdownOptionsResponse } from "./model";
import { FETCH_DROPDOWN_OPTIONS } from "../actions";
import { fetchDropdownOptionsAPI } from "../../APIs";
import messageStrings from "../../helpers/messageStrings";

const fetchDropdownOptionsAsync =
  async (): Promise<DropdownOptionsResponse> => {
    try {
      const response = await fetchDropdownOptionsAPI();
      return response.data;
    } catch (err) {
      throw err;
    }
  };

function* handleFetchDropdownOptions({}: {
  type: typeof FETCH_DROPDOWN_OPTIONS;
}) {
  try {
    const results: SagaReturnType<typeof fetchDropdownOptionsAsync> =
      yield call(fetchDropdownOptionsAsync);
    yield put(fetchDropdownOptionsSuccess(results));
  } catch (error: any) {
    yield put(
      fetchDropdownOptionsError(error.message || messageStrings.defaultError)
    );
  }
}

export function* watchFetchDropdownOptions() {
  yield takeEvery(FETCH_DROPDOWN_OPTIONS, handleFetchDropdownOptions);
}

export default function* rootSaga() {
  yield all([fork(watchFetchDropdownOptions)]);
}
