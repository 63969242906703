/* eslint-disable import/no-cycle */

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const FETCH_USER_DETAILS = "FETCH_USER_DETAILS";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_ERROR = "FETCH_USER_DETAILS_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const ENABLE_FORCE_LOGOUT = "ENABLE_FORCE_LOGOUT";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const CLEAR_AUTH_ERROR = "CLEAR_AUTH_ERROR";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
export const CLEAR_CHANGE_PASSWORD_ERROR = "CLEAR_CHANGE_PASSWORD_ERROR";

export * from "./auth/actions";

/* SIDEBAR */
export const SET_SIDEBAR_OPEN = "SET_SIDEBAR_OPEN";

/* DASHBOARD */
export const SET_DASHBOARD_TAB = "SET_DASHBOARD_TAB";
export const SET_DASHBOARD_BRAND_OPTION = "SET_DASHBOARD_BRAND_OPTION";
export const FETCH_DASHBOARD_DATA_ANALYTICS = "FETCH_DASHBOARD_DATA_ANALYTICS";
export const FETCH_DASHBOARD_DATA_ANALYTICS_SUCCESS =
  "FETCH_DASHBOARD_DATA_ANALYTICS_SUCCESS";
export const FETCH_DASHBOARD_DATA_ANALYTICS_ERROR =
  "FETCH_DASHBOARD_DATA_ANALYTICS_ERROR";

/* DROPDOWN-OPTIONS */
export const FETCH_DROPDOWN_OPTIONS = "FETCH_DROPDOWN_OPTIONS";
export const FETCH_DROPDOWN_OPTIONS_SUCCESS = "FETCH_DROPDOWN_OPTIONS_SUCCESS";
export const FETCH_DROPDOWN_OPTIONS_ERROR = "FETCH_DROPDOWN_OPTIONS_ERROR";

/* CUSTOMER-LISTING */
export const SET_CUSTOMER_FILTER = "SET_CUSTOMER_FILTER";
export const FETCH_CUSTOMER_LISTING = "FETCH_CUSTOMER_LISTING";
export const FETCH_CUSTOMER_LISTING_SUCCESS = "FETCH_CUSTOMER_LISTING_SUCCESS";
export const FETCH_CUSTOMER_LISTING_ERROR = "FETCH_CUSTOMER_LISTING_ERROR";
export const UPDATE_CUSTOMER_CHECKBOXES = "UPDATE_CUSTOMER_CHECKBOXES";
export const SET_CHANGE_BALANCE_MODAL_OPEN = "SET_CHANGE_BALANCE_MODAL_OPEN";
export const SET_EXPORT_ADDRESS_MODAL_OPEN = "SET_EXPORT_ADDRESS_MODAL_OPEN";

/* UNLINKED-CARD-LISTING */
export const SET_UNLINKED_CARD_FILTER = "SET_UNLINKED_CARD_FILTER";
export const FETCH_UNLINKED_CARD_LISTING = "FETCH_UNLINKED_CARD_LISTING";
export const FETCH_UNLINKED_CARD_LISTING_SUCCESS =
  "FETCH_UNLINKED_CARD_LISTING_SUCCESS";
export const FETCH_UNLINKED_CARD_LISTING_ERROR =
  "FETCH_UNLINKED_CARD_LISTING_ERROR";
export const ADD_CREATED_CARDS_TO_LISTING = "ADD_CREATED_CARDS_TO_LISTING";
export const SET_DELETE_UNLINKED_CARD = "SET_DELETE_UNLINKED_CARD";
export const DELETE_UNLINKED_CARD_FROM_LISTING =
  "DELETE_UNLINKED_CARD_FROM_LISTING";

/* TRANSACTION-LISTING */
export const SET_TRANSACTION_FILTER = "SET_TRANSACTION_FILTER";
export const FETCH_TRANSACTION_LISTING = "FETCH_TRANSACTION_LISTING";
export const FETCH_TRANSACTION_LISTING_SUCCESS =
  "FETCH_TRANSACTION_LISTING_SUCCESS";
export const FETCH_TRANSACTION_LISTING_ERROR =
  "FETCH_TRANSACTION_LISTING_ERROR";

/* BALANCE-CHANGE-LISTING */
export const SET_BALANCE_CHANGE_FILTER = "SET_BALANCE_CHANGE_FILTER";
export const FETCH_BALANCE_CHANGE_LISTING = "FETCH_BALANCE_CHANGE_LISTING";
export const FETCH_BALANCE_CHANGE_LISTING_SUCCESS =
  "FETCH_BALANCE_CHANGE_LISTING_SUCCESS";
export const FETCH_BALANCE_CHANGE_LISTING_ERROR =
  "FETCH_BALANCE_CHANGE_LISTING_ERROR";

/* USER-LISTING */
export const SET_USER_FILTER = "SET_USER_FILTER";
export const FETCH_USER_LISTING = "FETCH_USER_LISTING";
export const FETCH_USER_LISTING_SUCCESS = "FETCH_USER_LISTING_SUCCESS";
export const FETCH_USER_LISTING_ERROR = "FETCH_USER_LISTING_ERROR";

/* CUSTOMER DETAILS */
export const SET_ACTIVE_CUSTOMER_DETAILS_ID = "SET_ACTIVE_CUSTOMER_DETAILS_ID";
export const CLEAR_ACTIVE_CUSTOMER_DETAILS_ID =
  "CLEAR_ACTIVE_CUSTOMER_DETAILS_ID";
export const SHOW_STORED_CUSTOMER_DETAILS = "SHOW_STORED_CUSTOMER_DETAILS";
export const FETCH_CUSTOMER_DETAILS_SUCCESS = "FETCH_CUSTOMER_DETAILS_SUCCESS";
export const FETCH_CUSTOMER_DETAILS_ERROR = "FETCH_CUSTOMER_DETAILS_ERROR";

/* REPORT-LISTING */
export const SET_REPORT_FILTER = "SET_REPORT_FILTER";
