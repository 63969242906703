import axios from "axios";
import moment from "moment";

import { store } from "./index";
import { enableForceLogout } from "./redux/auth/actions";
import { unauthorizedRoute } from "./helpers/navLinks";
import { ENV, IS_ENV_PRODUCTION, SERVER_URL } from "./helpers/defaultValues";

import { RegisterCardCustomerReq } from "./views/publicCard/CardRegistration/CardRegistration";
import { CardRegistrationPaymentReq } from "./views/publicCard/CardRegistrationPayment/CardRegistrationPayment";
import { CardActivationFormValues } from "./views/publicCard/CardActivation/CardActivation";
import { CardTopupPaymentReq } from "./views/publicCard/CardTopup/CardTopup";
import { CustomerFilterState } from "./redux/customerModule/model";
import { UserFilterState } from "./redux/userModule/model";
import { TransactionListingReq } from "./redux/transactionModule/model";
import { BalanceChangeListingReq } from "./redux/balanceChangeModule/model";
import { UserAdminFormRequest } from "./components/UserAdminForm/UserAdminForm";
import { AddCustomerReq } from "./views/app/AddCustomer/AddCustomer";
import { EditCustomerReq } from "./views/app/EditCustomer/EditCustomer";
import { AddEditKioskUserRequest } from "./views/app/AddEditKioskUser/AddEditKioskUser";
import { EditSelectedBalancesReq } from "./components/ChangeBalanceModal/ChangeBalanceModal";
import { FetchAddressAndEditRegistrationStatusReq } from "./components/ExportAddressModal/ExportAddressModal";
import {
  TopUpSummaryFilters,
  CardRegistrationFeeFilters,
} from "./views/app/OtherReports/OtherReports";

import messageStrings from "./helpers/messageStrings";

//Add different base url to requests if in production environment
export const axiosInstance = axios.create({
  withCredentials: true,
  headers: { crossDomain: true, "Content-Type": "application/json" },
  // @ts-ignore
  baseURL: IS_ENV_PRODUCTION ? SERVER_URL[ENV] : "",
});

const INVALID_JWT_TOKEN_STATUS_CODES = [403];
const LOGOUT_URL = "/authentication/logoff";

// Listen for invalid JWT token on every bad response and log user out
axiosInstance.interceptors.response.use(
  function (response) {
    // Healthy status code
    return response;
  },
  function (error) {
    //Network error, no response, no status code
    if (!error.response) {
      return Promise.reject({ message: messageStrings.networkError });
    }

    // Unhealthy status code
    const errorStatusCode = error.response.status;

    //Invalid JWT status code
    if (INVALID_JWT_TOKEN_STATUS_CODES.includes(errorStatusCode)) {
      // API error was due to invalid jwt token, force logout
      // No access to router's history here
      // have to go through redux -> ForceLogout component (pick up history here)-> logout action
      const configURL = error.response.config.url;
      const { responseURL } = error.response.request;
      // Dispatch logout once, else infinite loop
      if (
        !configURL.includes(LOGOUT_URL) &&
        !responseURL.includes(LOGOUT_URL)
      ) {
        store.dispatch(enableForceLogout());
      }
    } else {
      //Other unhealthy status codes
      return Promise.reject(error.response.data);
    }
  }
);

export const getFAQData = () => {
  //Bust cache
  //TODO: change to daily cache bust
  return axios.get(
    `/resources/faq.json?timestamp=${moment().format("DDMMYYYYHHmmss")}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
};

export const loginAPI = (email: string, password: string) => {
  return axiosInstance.post("/authentication/login-web", {
    email,
    password,
  });
};

export const fetchUserDetailsAPI = () => {
  return axiosInstance.get("/authentication/user-details");
};

export const forgotPasswordAPI = (email: string) => {
  return axiosInstance.post("/authentication/forgot-password", {
    email,
    isWeb: true,
  });
};

export const resetPasswordAPI = (newPassword: string, resetToken: string) => {
  return axiosInstance.post("/authentication/reset-password", {
    password: newPassword,
    resetToken,
  });
};

export const changePasswordAPI = (oldPassword: string, newPassword: string) => {
  return axiosInstance.post("/authentication/change-password", {
    oldPassword,
    password: newPassword,
  });
};

export const logoutAPI = () => {
  return axiosInstance.post(LOGOUT_URL);
};

export const fetchDashboardAPI = () => {
  return axiosInstance.get("/data-analytics/dashboard");
};

export const validateRegisterCardCustomerAPI = (
  reqParams: RegisterCardCustomerReq
) => {
  return axiosInstance.post("/customer/card-customers", reqParams);
};

export const createCardRegistrationPaymentAPI = (
  reqParams: CardRegistrationPaymentReq
) => {
  return axiosInstance.post("/payment-request/card-registration", reqParams);
};

export const linkCardToCustomerAPI = (reqParams: CardActivationFormValues) => {
  return axiosInstance.post("/card/link", reqParams);
};

export const createCardTopupPaymentAPI = (reqParams: CardTopupPaymentReq) => {
  return axiosInstance.post("/payment-request/card-topup", reqParams);
};

export const fetchCustomerListAPI = (filters: CustomerFilterState) => {
  return axiosInstance.post("/customer/list", filters);
};

export const editSelectedBalancesAPI = (reqParams: EditSelectedBalancesReq) => {
  return axiosInstance.put("/card/balance", reqParams);
};

export const fetchAddressAndEditRegistrationStatusAPI = (
  reqParams: FetchAddressAndEditRegistrationStatusReq
) => {
  return axiosInstance.post("/customer/list", reqParams);
};

export const addCustomerAPI = (reqParams: AddCustomerReq) => {
  return axiosInstance.post("/customer/admin-customers", reqParams);
};

export const editCustomerAPI = (reqParams: EditCustomerReq) => {
  return axiosInstance.put("/customer/admin-customers", reqParams);
};

export const fetchFamilyAPI = (reqParams: { familyId: number }) => {
  return axiosInstance.get(`/customer/families/${reqParams.familyId}`);
};

export const fetchUnlinkedCardListAPI = () => {
  return axiosInstance.get("/card/unlinked/list");
};

export const addUnlinkedCardsAPI = (reqParams: string[]) => {
  return axiosInstance.post("/card/unlinked", reqParams);
};

export const deleteUnlinkedCardAPI = (reqParams: { cardId: number }) => {
  return axiosInstance.delete(`/card/unlinked/${reqParams.cardId.toString()}`);
};

export const fetchTransactionListAPI = (filters: TransactionListingReq) => {
  return axiosInstance.post("/transaction/list", filters);
};

export const fetchTransactionsExportAPI = (filters: TransactionListingReq) => {
  return axiosInstance.post("/transaction/list", {
    ...filters,
    isExport: true,
  });
};

export const fetchBalanceChangeListAPI = (filters: BalanceChangeListingReq) => {
  return axiosInstance.post("/balance-change/list", filters);
};

export const fetchBalanceChangeExportAPI = (
  filters: BalanceChangeListingReq
) => {
  return axiosInstance.post("/balance-change/list", {
    ...filters,
    isExport: true,
  });
};

export const fetchTopUpSummaryExportAPI = (filters: TopUpSummaryFilters) => {
  return axiosInstance.post("/balance-change/top-up-summary", filters);
};

export const fetchCardRegistrationFeeExportAPI = (
  filters: CardRegistrationFeeFilters
) => {
  return axiosInstance.post("/card-fee/list", { ...filters, isExport: true });
};

export const fetchUserListAPI = (filters: UserFilterState) => {
  return axiosInstance.post("/user/list", filters);
};

export const addAdminAPI = (reqParams: UserAdminFormRequest) => {
  return axiosInstance.post("/user/admin", reqParams);
};

export const fetchAdminUserAPI = (reqParams: { userId: number }) => {
  return axiosInstance.get(`/user/admins/${reqParams.userId.toString()}`);
};

export const editAdminAPI = (reqParams: UserAdminFormRequest) => {
  return axiosInstance.put("/user/admin", reqParams);
};

export const fetchDropdownOptionsAPI = () => {
  return axiosInstance.get("/brand/options");
};

export const fetchOutletKioskUserAPI = (reqParams: { outletId: string }) => {
  return axiosInstance.get(`/outlet/${reqParams.outletId}`);
};

export const addEditKioskUserAPI = (reqParams: AddEditKioskUserRequest) => {
  return axiosInstance.post("/user/merchant", reqParams);
};

export const fetchCustomerDetailsAPI = (customerId: number) => {
  return axiosInstance.post("/customer/details", {
    id: customerId,
    hasTransactionBalanceChange: true,
  });
};
