import { AccessRights } from "../redux/auth/model";

export interface NavLinkType {
  id: string;
  text: string;
  to: string;
  icon?: string;
  // roles?: number[];
  accessRights?: AccessRights[];
  newWindow?: boolean;
}

export const userRoute = "/user";
export const loginRoute = userRoute + "/login";
export const userSuccessRoute = userRoute + "/success";

export const publicRoute = "/public";
export const publicHomeRoute = publicRoute + "/home";
export const publicFAQRoute = publicRoute + "/faq";
export const publicContactRoute = publicRoute + "/contact-us";
export const publicOutletsRoute = publicRoute + "/outlets";
export const publicCardRoute = publicRoute + "/card";
export const cardRegistrationRoute = publicCardRoute + "/register";
export const cardSuccessRoute = publicCardRoute + "/success";
export const cardActivationRoute = publicCardRoute + "/activate";
export const cardRegistrationPaymentRoute =
  publicCardRoute + "/registration-pay";
export const cardTopupRoute = publicCardRoute + "/top-up";

export const appRoute = "/app";
export const dashboardRoute = appRoute + "/dashboard";
export const profileRoute = appRoute + "/profile";
export const transactionHistoryRoute = appRoute + "/transactions";
export const cardBalanceChangeHistoryRoute = appRoute + "/balance-changes";
export const otherReportsRoute = appRoute + "/reports";
export const manageCustomersRoute = appRoute + "/customers";
export const addCustomerRoute = appRoute + "/customers/new";
export const editCustomerRoute = appRoute + "/customers/:familyId/edit";
export const unlinkedCardsRegistryRoute = appRoute + "/unlinked-cards";
export const manageUsersRoute = appRoute + "/users";
export const addAdminRoute = appRoute + "/users/admins/new";
export const editAdminRoute = appRoute + "/users/admins/:userId/edit";
export const addEditKioskRoute = appRoute + "/users/kiosks/:outletId/edit";
export const appSuccessRoute = appRoute + "/success";
export const appErrorRoute = appRoute + "/error";
export const unauthorizedRoute = "/unauthorized";

export const navLinksData: NavLinkType[] = [
  {
    id: "dashboard",
    text: "Dashboard",
    icon: "icon icon-bar-chart-4",
    to: dashboardRoute,
  },
  {
    id: "customers",
    text: "Manage Customers",
    icon: "icon icon-profile",
    to: manageCustomersRoute,
    accessRights: [AccessRights.VIEW_CUSTOMER, AccessRights.ADD_EDIT_CUSTOMER],
  },
  {
    id: "unlinked-cards-registry",
    text: "Unlinked Cards Registry",
    icon: "icon icon-credit-card",
    to: unlinkedCardsRegistryRoute,
    accessRights: [
      AccessRights.VIEW_UNLINKED_CARD,
      AccessRights.ADD_DELETE_UNLINKED_CARD,
    ],
  },
  {
    id: "transactions",
    text: "Transaction History",
    icon: "icon icon-dollar",
    to: transactionHistoryRoute,
    accessRights: [AccessRights.VIEW_TRANSACTION_HISTORY],
  },
  {
    id: "balance-change",
    text: "Card Balance Change History",
    icon: "icon icon-clock-back",
    to: cardBalanceChangeHistoryRoute,
    accessRights: [AccessRights.VIEW_CARD_BALANCE_CHANGE_HISTORY],
  },
  {
    id: "other-reports",
    text: "Other Reports",
    icon: "icon icon-letter-open",
    to: otherReportsRoute,
    accessRights: [AccessRights.EXPORT_OTHER_FINANCE_REPORTS],
  },

  {
    id: "users",
    text: "Manage Users",
    icon: "icon icon-conference",
    to: manageUsersRoute,
    accessRights: [
      AccessRights.VIEW_USER,
      AccessRights.ADD_EDIT_ADMIN_USER,
      AccessRights.ADD_EDIT_MERCHANT_USER,
    ],
  },
];

export const mapSecondaryToPrimary = [
  {
    secondaryRegExp: new RegExp(`\\${appRoute}\\/users\\/.*\\/edit`, "i"),
    primaryRoute: manageUsersRoute,
  },
  {
    secondaryPath: profileRoute,
    primaryRoute: dashboardRoute,
  },
  {
    secondaryPath: addAdminRoute,
    primaryRoute: manageUsersRoute,
  },
  {
    secondaryPath: addCustomerRoute,
    primaryRoute: manageCustomersRoute,
  },
  {
    secondaryRegExp: new RegExp(`\\${appRoute}\\/customers\\/.*\\/edit`, "i"),
    primaryRoute: manageCustomersRoute,
  },
];

export const settingsLinksData: NavLinkType[] = [
  {
    id: "profile",
    text: "Profile",
    to: `${appRoute}/profile`,
  },
];
