import {
  all,
  call,
  fork,
  put,
  takeEvery,
  SagaReturnType,
} from "redux-saga/effects";
import { History } from "history";

import {
  LOGIN_USER,
  FETCH_USER_DETAILS,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
} from "../actions";

import {
  loginUserSuccess,
  loginUserError,
  fetchUserDetailsSuccess,
  fetchUserDetailsError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  changePasswordSuccess,
  changePasswordError,
  logoutUser,
  enableForceLogout,
} from "./actions";

import {
  loginAPI,
  fetchUserDetailsAPI,
  logoutAPI,
  forgotPasswordAPI,
  resetPasswordAPI,
  changePasswordAPI,
} from "../../APIs";

import {
  LoginResponse,
  LogoutResponse,
  FetchUserDetailsResponse,
  ForgotPasswordResponse,
  ResetPasswordResponse,
  ChangePasswordResponse,
} from "./model";
import { appRoute, loginRoute, userSuccessRoute } from "../../helpers/navLinks";
import { setTokenExpirationDateInLocalStorage } from "../../helpers/utils";
import messageStrings from "../../helpers/messageStrings";

const loginAsync = async (
  email: string,
  password: string
): Promise<LoginResponse> => {
  try {
    const response = await loginAPI(email, password);
    return response.data;
  } catch (err: any) {
    throw err;
  }
};

function* handleLogin({
  payload,
}: {
  type: typeof LOGIN_USER;
  payload: { history: History; user: { email: string; password: string } };
}) {
  const {
    history,
    user: { email, password },
  } = payload;

  try {
    const responseData: SagaReturnType<typeof loginAsync> = yield call(
      loginAsync,
      email,
      password
    );
    // Store token expiration date in local storage
    // to auto fetch user details on page refresh till jwt token expires
    setTokenExpirationDateInLocalStorage(
      responseData.jwtExpirationDate || null
    );

    // Store user details in redux store (in memory only)
    if (responseData.user) {
      yield put(loginUserSuccess(responseData.user));
    }
    // Redirect to dashboard
    history.push(appRoute);
  } catch (error: any) {
    yield put(loginUserError(error.message || messageStrings.defaultError));
  }
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, handleLogin);
}

const fetchUserDetailsAsync = async (): Promise<FetchUserDetailsResponse> => {
  try {
    const response = await fetchUserDetailsAPI();
    return response.data;
  } catch (err: any) {
    throw err;
  }
};

function* handleFetchUserDetails() {
  try {
    const responseData: SagaReturnType<typeof fetchUserDetailsAsync> =
      yield call(fetchUserDetailsAsync);

    yield put(fetchUserDetailsSuccess(responseData));
  } catch (error: any) {
    yield put(
      fetchUserDetailsError(error.message || messageStrings.defaultError)
    );
    yield put(enableForceLogout());
  }
}

export function* watchFetchUserDetails() {
  yield takeEvery(FETCH_USER_DETAILS, handleFetchUserDetails);
}

const logoutAsync = async (): Promise<LogoutResponse> => {
  try {
    const response = await logoutAPI();
    return response.data;
  } catch (err: any) {
    return err;
  }
};

function* handleLogout({
  payload,
}: {
  type: typeof LOGOUT_USER;
  payload: { history: History; message: string; isWithoutRedirection: boolean };
}) {
  const { history, isWithoutRedirection } = payload;

  // Remove jwtTokenExpirationDate from local storage
  setTokenExpirationDateInLocalStorage(null);

  // By default, redirect user to login page
  if (!isWithoutRedirection) {
    // Prevent multiple redirection
    if (history.location.pathname !== loginRoute) {
      // Redirect to login page
      history.push(loginRoute);
    }
  }

  // Inform server to clear jwt token
  yield call(logoutAsync);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, handleLogout);
}

const forgotPasswordAsync = async (
  email: string
): Promise<ForgotPasswordResponse> => {
  try {
    const response = await forgotPasswordAPI(email);
    return response.data;
  } catch (err: any) {
    throw err;
  }
};

function* handleForgotPassword({
  payload,
}: {
  type: typeof FORGOT_PASSWORD;
  payload: { email: string; history: History };
}) {
  const { email, history } = payload;
  try {
    const responseData: SagaReturnType<typeof forgotPasswordAsync> = yield call(
      forgotPasswordAsync,
      email
    );

    yield put(forgotPasswordSuccess());
    // Redirect to success page
    history.push(userSuccessRoute, {
      hasLoginRedirect: true,
      message: responseData.message || messageStrings.forgotPasswordSuccess,
    });
  } catch (error: any) {
    yield put(
      forgotPasswordError(error.message || messageStrings.defaultError)
    );
  }
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, handleForgotPassword);
}

const resetPasswordAsync = async (
  newPassword: string,
  resetToken: string
): Promise<ResetPasswordResponse> => {
  try {
    const response = await resetPasswordAPI(newPassword, resetToken);
    return response.data;
  } catch (err: any) {
    throw err;
  }
};

function* handleResetPassword({
  payload,
}: {
  type: typeof RESET_PASSWORD;
  payload: {
    newPassword: string;
    resetToken: string;
    isFromMobile: boolean;
    history: History;
  };
}) {
  const { newPassword, resetToken, isFromMobile, history } = payload;
  try {
    const responseData: SagaReturnType<typeof resetPasswordAsync> = yield call(
      resetPasswordAsync,
      newPassword,
      resetToken
    );

    yield put(resetPasswordSuccess());

    if (isFromMobile) {
      history.push(userSuccessRoute, {
        hasLoginRedirect: false,
        message: messageStrings.mobilePasswordResetSuccess,
      });
    } else {
      history.push(userSuccessRoute, {
        hasLoginRedirect: true,
        message: responseData.message || messageStrings.webPasswordResetSuccess,
      });
    }
  } catch (error: any) {
    yield put(resetPasswordError(error.message || messageStrings.defaultError));
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, handleResetPassword);
}

const changePasswordAsync = async (
  oldPassword: string,
  newPassword: string
): Promise<ChangePasswordResponse> => {
  try {
    const response = await changePasswordAPI(oldPassword, newPassword);
    return response.data;
  } catch (err: any) {
    throw err;
  }
};

function* handleChangePassword({
  payload,
}: {
  type: typeof CHANGE_PASSWORD;
  payload: { oldPassword: string; newPassword: string; history: History };
}) {
  const { oldPassword, newPassword, history } = payload;

  try {
    const responseData: SagaReturnType<typeof changePasswordAsync> = yield call(
      changePasswordAsync,
      oldPassword,
      newPassword
    );

    yield put(changePasswordSuccess());

    history.push(userSuccessRoute, {
      hasLoginRedirect: true,
      message: responseData.message || messageStrings.changePasswordSuccess,
    });

    // Log out user without redirecting to login page
    yield put(logoutUser(history, "", true));
  } catch (error: any) {
    yield put(
      changePasswordError(error.message || messageStrings.defaultError)
    );
  }
}

export function* watchChangePassword() {
  yield takeEvery(CHANGE_PASSWORD, handleChangePassword);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchFetchUserDetails),
    fork(watchLogoutUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchChangePassword),
  ]);
}
