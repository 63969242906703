export enum DashboardTab {
  ORGANIZATION = "organization",
  BRAND = "brand",
}

export interface OrganizationDataAnalytics {
  uniqueCustomerCountByMonth: { month: string; uniqueCount: number }[];
  uniqueCustomersCount?: number;
  cardOrderCount: number;
  totalCardTransactionAmount: number;
  cardTopUpCount: number;
  customersByAgeGroup: { [key: string]: number };
  customersByEthnicity: { [key: string]: number };
}

export interface BrandDataAnalytics {
  refId: string;
  brandId: string;
  data: {
    uniqueCustomerCountByMonth: { month: string; uniqueCount: number }[];
    cardOrderCount: number;
    totalCardTransactionAmount: number;
    cardTopUpCount: number;
    cardTopUpAmount?: number;
    customersByAgeGroup: { [key: string]: number };
    customersByEthnicity: { [key: string]: number };
  };
}

export interface DashboardAnalysedData {
  organization: OrganizationDataAnalytics | null;
  brands: BrandDataAnalytics[];
  timestamp: Date | null;
}

export interface DashboardDataAnalyticsResponse {
  data: {
    type: "organization" | "month-end-brand";
    refId: string;
    jsonData: OrganizationDataAnalytics | BrandDataAnalytics["data"];
    createdAt: Date;
  }[];
  message?: string;
}

export interface DashboardAction {
  type: string;
  payload: DashboardAnalysedData;
}

export interface DashboardState {
  tab: DashboardTab;
  brandOption: string;
  data: DashboardAnalysedData | null;
  error: string;
  loading: boolean;
}
