/* eslint-disable global-require */
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./redux/store";

import SpinnerLoader from "./components/SpinnerLoader/SpinnerLoader";

import "./assets/css/vendor/bootstrap.min.css";
import "./assets/css/vendor/bootstrap.rtl.only.min.css";

import "./assets/css/main.scss";

const App = React.lazy(() => import(/* webpackChunkName: "App" */ "./App"));

// eslint-disable-next-line import/prefer-default-export
export const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback={<SpinnerLoader size="lg" />}>
        <App />
      </Suspense>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
