import {
  CustomerFilter,
  CustomerListing,
  CustomerCheckbox,
  ChangeBalanceModalOpenState,
  ExportAddressModalOpenState,
} from "./model";

import {
  SET_CUSTOMER_FILTER,
  FETCH_CUSTOMER_LISTING,
  FETCH_CUSTOMER_LISTING_SUCCESS,
  FETCH_CUSTOMER_LISTING_ERROR,
  UPDATE_CUSTOMER_CHECKBOXES,
  SET_CHANGE_BALANCE_MODAL_OPEN,
  SET_EXPORT_ADDRESS_MODAL_OPEN,
} from "../actions";

export const setCustomerFilter = (newFiltersKeyValues: CustomerFilter) => {
  return { type: SET_CUSTOMER_FILTER, payload: newFiltersKeyValues };
};

export const fetchCustomerListing = (filters: { [key: string]: any }) => {
  return { type: FETCH_CUSTOMER_LISTING, payload: filters };
};

export const fetchCustomerListingSuccess = (results: CustomerListing) => {
  return { type: FETCH_CUSTOMER_LISTING_SUCCESS, payload: results };
};

export const fetchCustomerListingError = (message: string) => {
  return { type: FETCH_CUSTOMER_LISTING_ERROR, payload: message };
};

export const updateCustomerCheckboxes = (checkboxes: CustomerCheckbox[]) => {
  return { type: UPDATE_CUSTOMER_CHECKBOXES, payload: checkboxes };
};

export const setChangeBalanceModalOpen = (
  isOpen: ChangeBalanceModalOpenState
) => {
  return { type: SET_CHANGE_BALANCE_MODAL_OPEN, payload: isOpen };
};

export const setExportAddressModalOpen = (
  isOpen: ExportAddressModalOpenState
) => {
  return { type: SET_EXPORT_ADDRESS_MODAL_OPEN, payload: isOpen };
};
