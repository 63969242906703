import moment from "moment";
import {
  TransactionFilterState,
  TransactionFilterAction,
  TransactionListingState,
  TransactionAction,
} from "./model";
import {
  SET_TRANSACTION_FILTER,
  FETCH_TRANSACTION_LISTING,
  FETCH_TRANSACTION_LISTING_SUCCESS,
  FETCH_TRANSACTION_LISTING_ERROR,
} from "../actions";

const INIT_TRANSACTION_FILTER_STATE: TransactionFilterState = {
  currentPage: 1,
  query: "",
  paymentMethod: "",
  outlets: [],
  startDate: moment().subtract(1, "days").format("DD/MM/YYYY"),
  endDate: moment().format("DD/MM/YYYY"),
  sortBy: "transactionDate",
  sortOrder: "DESC",
};

export const transactionFilterReducer = (
  state: TransactionFilterState = INIT_TRANSACTION_FILTER_STATE,
  action: TransactionFilterAction
) => {
  switch (action.type) {
    case SET_TRANSACTION_FILTER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const INIT_TRANSACTION_STATE: TransactionListingState = {
  results: null,
  loading: false,
  error: "",
};

export const transactionListingReducer = (
  state: TransactionListingState = INIT_TRANSACTION_STATE,
  action: TransactionAction
) => {
  switch (action.type) {
    case FETCH_TRANSACTION_LISTING:
      return { ...state, loading: true, error: "" };
    case FETCH_TRANSACTION_LISTING_SUCCESS:
      return { loading: false, error: "", results: { ...action.payload } };
    case FETCH_TRANSACTION_LISTING_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
