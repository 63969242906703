import moment from "moment";
import {
  JWT_EXPIRATION_DATE_LOCAL_STORAGE_KEY,
  DEVICE_BREAKPOINTS,
  EMAIL_VALIDATION_REGEXP,
} from "./defaultValues";

import messageStrings from "./messageStrings";
import { validatePasswordRequirements } from "../components/PasswordChecker/PasswordChecker";

export const mapOrder = (
  array: { [key: string]: any }[],
  order: string[],
  key: string
) => {
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const setTokenExpirationDateInLocalStorage = (
  jwtExpirationDate: Date | null
) => {
  try {
    if (jwtExpirationDate) {
      localStorage.setItem(
        JWT_EXPIRATION_DATE_LOCAL_STORAGE_KEY,
        moment(jwtExpirationDate).format("DD/MM/YYYY HH:mm:ss")
      );
    } else {
      localStorage.removeItem(JWT_EXPIRATION_DATE_LOCAL_STORAGE_KEY);
    }
  } catch (error: any) {
    console.log(
      ">>>>: src/helpers/Utils.js : setTokenExpirationDateInLocalStorage -> error",
      error
    );
  }
};
export const getTokenExpirationDateFromLocalStorage = () => {
  let jwtExpirationDate = null;

  try {
    jwtExpirationDate =
      localStorage.getItem(JWT_EXPIRATION_DATE_LOCAL_STORAGE_KEY) || "";
  } catch (error: any) {
    console.log(
      ">>>>: src/helpers/Utils.js : getTokenExpirationDateFromLocalStorage -> error",
      error
    );
  }
  return jwtExpirationDate;
};

export const hasValidJWTToken = () => {
  const jwtExpirationDate = getTokenExpirationDateFromLocalStorage();
  let isExpired = false;
  let error = "";

  if (jwtExpirationDate) {
    isExpired = moment().isAfter(
      moment(jwtExpirationDate, "DD/MM/YYYY HH:mm:ss")
    );

    if (isExpired) error = messageStrings.relogin;
  } else {
    error = messageStrings.login;
  }

  return {
    isJWTTokenValid: jwtExpirationDate && !isExpired,
    jwtTokenError: error,
  };
};

export const getBrowserWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

export const getIsMobile = () => {
  return getBrowserWidth() < DEVICE_BREAKPOINTS.desktop;
};

export const formatToCurrency = (
  num: number,
  forceCents: boolean = true,
  hasDollarSign: boolean = true
) => {
  let negativeSign = num < 0 ? "-" : "";
  let processedNum = Math.abs(num);

  if (forceCents) {
    processedNum = Math.round(Math.abs(num) * 100) / 100;
  }
  // 1900.30 -> 1,900.30
  return `${negativeSign}${
    hasDollarSign ? "$" : ""
  }${processedNum.toLocaleString("en-US", {
    style: "decimal",
    minimumFractionDigits: forceCents ? 2 : 0,
  })}`;
};

export const formatToReadableWholeNumber = (num: number) => {
  let processedNum = Math.round(num);

  return processedNum.toLocaleString("en-US", {
    style: "decimal",
    minimumFractionDigits: 0,
  });
};

export const formatDateTime = (dateTimeString: string | Date) => {
  return moment(dateTimeString).format("DD/MM/YYYY hh:mmA");
};

export const formatDateTimeToCsv = (dateTimeString: string | Date) => {
  return moment(dateTimeString).format("YYYY/MM/DD HH:mm:ss");
};

export const getDateFromStr = (str: string) => {
  return moment(str, "DD/MM/YYYY").toDate();
};

export const formatDate = (date: Date) => {
  return moment(date).format("DD/MM/YYYY");
};

export const truncateString = (str: string, maxLength: number) => {
  if (str.length <= maxLength) {
    return str;
  }

  return str.substring(0, maxLength).concat("...");
};

export const capitalizeFirstChar = (string: string) => {
  const words = string.split(" ");

  return words
    .map((word) => (word ? word[0].toUpperCase() + word.substring(1) : ""))
    .join(" ");
};

export const validateRequiredEmail = (value: string) => {
  const email = value ? value.replace(/\s/g, "") : "";
  let error;

  if (!email) {
    error = messageStrings.requiredEmail;
  } else if (!EMAIL_VALIDATION_REGEXP.test(email)) {
    error = messageStrings.invalidEmail;
  }
  return error;
};

export const validateOptionalEmail = (value: string) => {
  let email = value;
  let error;
  if (email) {
    email = value.replace(/\s/g, "");
  }
  if (email && !EMAIL_VALIDATION_REGEXP.test(email)) {
    error = messageStrings.invalidEmail;
  }
  return error;
};

export const validateOptionalDate = (value: string) => {
  let error;
  if (value && !moment(value, "DD/MM/YYYY", true).isValid()) {
    error = messageStrings.invalidDateFormat;
  }
  return error;
};

export const validateRequired = (value: string) => {
  let error;

  if (!value) {
    error = messageStrings.requiredInput;
  }
  return error;
};

export const validateRequiredMobile = (value: string) => {
  let error;

  if (!value) {
    error = messageStrings.requiredInput;
  }

  if (value.length !== 8) {
    error = messageStrings.invalidMobileLength;
  }

  if (value[0] !== "8" && value[0] !== "9") {
    error = messageStrings.invalidMobileNumber;
  }

  return error;
};

export const validateRequiredOption = (value: string) => {
  let error;
  if (!value) {
    error = messageStrings.requiredOption;
  }
  return error;
};

export const validateAtLeastOneOption = (values: any) => {
  let error;
  if (!values || !Array.isArray(values) || !values.length) {
    error = messageStrings.requireAtLeastOne;
  }
  return error;
};

export const validateNewAndConfirmPasswords = (values: {
  newPassword: string;
  confirmPassword: string;
}) => {
  const { newPassword, confirmPassword } = values;
  const errors: { confirmPassword?: string; newPassword?: string } = {};
  const results = validatePasswordRequirements(newPassword);

  if (!results.hasPassedAll) {
    errors.newPassword = messageStrings.newPasswordFail;
  }

  if (confirmPassword && newPassword && newPassword !== confirmPassword) {
    errors.confirmPassword = messageStrings.confirmNotMatchNewPassword;
  } else if (!confirmPassword) {
    errors.confirmPassword = messageStrings.noConfirmPassword;
  }
  return errors;
};

export const preventNonDigitDecimalKeys = (event: React.KeyboardEvent) => {
  const key = event.key.toLowerCase();
  if (!"0123456789.".includes(key)) {
    event.preventDefault();
  }
};
export const allowDigitAndDecimal = (valStr: string) => {
  return valStr.replace(/[^0-9.]/g, "");
};

export const preventNonDigitKeys = (event: React.KeyboardEvent) => {
  const key = event.key.toLowerCase();
  if (!"0123456789".includes(key)) {
    event.preventDefault();
  }
};

export const allowDigitOnly = (valStr: string) => {
  return valStr.replace(/[^0-9]/g, "");
};

export const preventNonAlphaNumericKeys = (event: React.KeyboardEvent) => {
  const key = event.key.toLowerCase();
  if (/[^a-zA-Z0-9]/.test(key)) {
    event.preventDefault();
  }
};

export const allowAlphaNumeric = (valStr: string) => {
  return valStr.replace(/[^a-zA-Z0-9]/g, "");
};

export const limitCents = (valStr: string) => {
  let truncatedVal = valStr.replace(/-/g, "");
  const dotIndex = truncatedVal.indexOf(".");
  if (dotIndex > -1 && truncatedVal.length > dotIndex + 3) {
    truncatedVal = truncatedVal.substring(0, dotIndex + 3);
  }

  return truncatedVal;
};

export const allowAlphaNumericNewline = (valStr: string) => {
  return valStr.replace(/[^a-zA-Z0-9\n\r]/g, "");
};

export const throwErrorIfDuplicateCustomer = (values: {
  firstName?: string;
  lastName?: string;
  supplementary: { firstName?: string; lastName?: string }[];
}) => {
  let error;

  //Check duplicated customers using first name and last name
  const customerNames: string[] = [
    `${values.firstName && values.firstName.toLowerCase()} ${
      values.lastName && values.lastName.toLowerCase()
    }`,
  ];
  const duplicatedCustomers: string[] = [];

  for (const supp of values.supplementary) {
    const name = `${supp.firstName && supp.firstName.toLowerCase()} ${
      supp.lastName && supp.lastName.toLowerCase()
    }`;
    if (customerNames.includes(name)) {
      if (!duplicatedCustomers.includes(name) && name !== " ") {
        duplicatedCustomers.push(name);
      }
    } else {
      customerNames.push(name);
    }
  }

  if (duplicatedCustomers.length) {
    error = `Customers ${capitalizeFirstChar(
      duplicatedCustomers.join(", ")
    )} are duplicates in the form and cannot register more than once`;
  }

  return error;
};
