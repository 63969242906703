import { BalanceChangeFilter, BalanceChangeListing } from "./model";

import {
  SET_BALANCE_CHANGE_FILTER,
  FETCH_BALANCE_CHANGE_LISTING,
  FETCH_BALANCE_CHANGE_LISTING_SUCCESS,
  FETCH_BALANCE_CHANGE_LISTING_ERROR,
} from "../actions";

export const setBalanceChangeFilter = (
  newFiltersKeyValues: BalanceChangeFilter
) => {
  return { type: SET_BALANCE_CHANGE_FILTER, payload: newFiltersKeyValues };
};

export const fetchBalanceChangeListing = (filters: { [key: string]: any }) => {
  return { type: FETCH_BALANCE_CHANGE_LISTING, payload: filters };
};

export const fetchBalanceChangeListingSuccess = (
  results: BalanceChangeListing
) => {
  return { type: FETCH_BALANCE_CHANGE_LISTING_SUCCESS, payload: results };
};

export const fetchBalanceChangeListingError = (message: string) => {
  return { type: FETCH_BALANCE_CHANGE_LISTING_ERROR, payload: message };
};
