import { combineReducers } from "redux";
import { authReducer, changePasswordReducer } from "./auth/reducer";
import dashboardReducer from "./dashboardModule/reducer";
import {
  customerFilterReducer,
  customerListingReducer,
  customerCheckboxesReducer,
  changeBalanceModalOpenReducer,
  exportAddressModalOpenReducer,
} from "./customerModule/reducer";
import {
  unlinkedCardFilterReducer,
  unlinkedCardListingReducer,
  deleteUnlinkedCardReducer,
} from "./unlinkedCardModule/reducer";
import { userFilterReducer, userListingReducer } from "./userModule/reducer";
import {
  transactionFilterReducer,
  transactionListingReducer,
} from "./transactionModule/reducer";
import {
  balanceChangeFilterReducer,
  balanceChangeListingReducer,
} from "./balanceChangeModule/reducer";
import { reportFilterReducer } from "./reportModule/reducer";
import customerDetailsReducer from "./customerDetails/reducer";
import sidebarReducer from "./sidebar/reducer";
import { dropdownOptionsReducer } from "./dropdownOptions/reducer";

import { AuthState, ChangePasswordState } from "./auth/model";
import { SidebarState } from "./sidebar/model";
import { DashboardState } from "./dashboardModule/model";
import {
  CustomerFilterState,
  CustomerListingState,
  CustomerCheckboxesState,
  ChangeBalanceModalOpenState,
  ExportAddressModalOpenState,
} from "./customerModule/model";
import {
  UnlinkedCardFilterState,
  UnlinkedCardListingState,
  DeleteUnlinkedCardState,
} from "./unlinkedCardModule/model";
import { UserFilterState, UserListingState } from "./userModule/model";
import {
  TransactionFilterState,
  TransactionListingState,
} from "./transactionModule/model";
import {
  BalanceChangeFilterState,
  BalanceChangeListingState,
} from "./balanceChangeModule/model";
import { ReportFilterState } from "./reportModule/model";
import { CustomerDetailsState } from "./customerDetails/model";

import { DropdownOptionsState } from "./dropdownOptions/model";

const reducers = combineReducers({
  authUser: authReducer,
  changePassword: changePasswordReducer,
  sidebar: sidebarReducer,
  dashboard: dashboardReducer,
  customerFilter: customerFilterReducer,
  customerListing: customerListingReducer,
  customerCheckboxes: customerCheckboxesReducer,
  changeBalanceModalOpen: changeBalanceModalOpenReducer,
  exportAddressModalOpen: exportAddressModalOpenReducer,
  unlinkedCardFilter: unlinkedCardFilterReducer,
  unlinkedCardListing: unlinkedCardListingReducer,
  deleteUnlinkedCard: deleteUnlinkedCardReducer,
  transactionFilter: transactionFilterReducer,
  transactionListing: transactionListingReducer,
  balanceChangeFilter: balanceChangeFilterReducer,
  balanceChangeListing: balanceChangeListingReducer,
  reportFilter: reportFilterReducer,
  userFilter: userFilterReducer,
  userListing: userListingReducer,
  dropdownOptions: dropdownOptionsReducer,
  customerDetails: customerDetailsReducer,
});

export interface RootState {
  authUser: AuthState;
  changePassword: ChangePasswordState;
  sidebar: SidebarState;
  dashboard: DashboardState;
  customerFilter: CustomerFilterState;
  customerListing: CustomerListingState;
  customerCheckboxes: CustomerCheckboxesState;
  changeBalanceModalOpen: ChangeBalanceModalOpenState;
  exportAddressModalOpen: ExportAddressModalOpenState;
  unlinkedCardFilter: UnlinkedCardFilterState;
  unlinkedCardListing: UnlinkedCardListingState;
  deleteUnlinkedCard: DeleteUnlinkedCardState;
  transactionFilter: TransactionFilterState;
  transactionListing: TransactionListingState;
  balanceChangeFilter: BalanceChangeFilterState;
  balanceChangeListing: BalanceChangeListingState;
  reportFilter: ReportFilterState;
  userFilter: UserFilterState;
  userListing: UserListingState;
  dropdownOptions: DropdownOptionsState;
  customerDetails: CustomerDetailsState;
}

export type RootStateKeys = keyof RootState;

export default reducers;
