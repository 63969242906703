import {
  CustomerFilterState,
  CustomerFilterAction,
  CustomerListingState,
  CustomerAction,
  CustomerCheckboxesState,
  CustomerCheckboxesAction,
  CustomerItem,
  CustomerCheckbox,
  ChangeBalanceModalOpenState,
  ChangeBalanceModalOpenAction,
  ExportAddressModalOpenState,
  ExportAddressModalOpenAction,
} from "./model";
import {
  SET_CUSTOMER_FILTER,
  FETCH_CUSTOMER_LISTING,
  FETCH_CUSTOMER_LISTING_SUCCESS,
  FETCH_CUSTOMER_LISTING_ERROR,
  UPDATE_CUSTOMER_CHECKBOXES,
  SET_CHANGE_BALANCE_MODAL_OPEN,
  SET_EXPORT_ADDRESS_MODAL_OPEN,
} from "../actions";

const INIT_CUSTOMER_FILTER_STATE: CustomerFilterState = {
  currentPage: 1,
  query: "",
  card: "",
  sortBy: "customerName",
  sortOrder: "ASC",
};

export const customerFilterReducer = (
  state: CustomerFilterState = INIT_CUSTOMER_FILTER_STATE,
  action: CustomerFilterAction
) => {
  switch (action.type) {
    case SET_CUSTOMER_FILTER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const INIT_CUSTOMER_STATE: CustomerListingState = {
  results: null,
  loading: false,
  error: "",
};

export const customerListingReducer = (
  state: CustomerListingState = INIT_CUSTOMER_STATE,
  action: CustomerAction
) => {
  switch (action.type) {
    case FETCH_CUSTOMER_LISTING:
      return { ...state, loading: true, error: "" };
    case FETCH_CUSTOMER_LISTING_SUCCESS:
      return { loading: false, error: "", results: { ...action.payload } };
    case FETCH_CUSTOMER_LISTING_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const CUSTOMER_CHECKBOX_FIELD_NAME_ID_PREPEND = "customer-listing-id-";
const INIT_CUSTOMER_CHECKBOXES_STATE: CustomerCheckboxesState = null;

export const customerCheckboxesReducer = (
  state: CustomerCheckboxesState = INIT_CUSTOMER_CHECKBOXES_STATE,
  action: CustomerCheckboxesAction | CustomerAction
) => {
  switch (action.type) {
    case FETCH_CUSTOMER_LISTING:
      return null;
    case FETCH_CUSTOMER_LISTING_ERROR:
      return null;
    case FETCH_CUSTOMER_LISTING_SUCCESS:
      if (!action.payload || !("items" in action.payload)) {
        return state;
      }
      return action.payload.items.map((customer: CustomerItem) => ({
        id: customer.id,
        fieldName: CUSTOMER_CHECKBOX_FIELD_NAME_ID_PREPEND + customer.id, //Needs to match with checkboxConfig fieldName in JSX
        isChecked: false,
      }));
    case UPDATE_CUSTOMER_CHECKBOXES:
      if (!Array.isArray(action.payload)) {
        return state;
      }
      return [
        ...action.payload.map((checkbox: CustomerCheckbox) => ({
          ...checkbox,
        })),
      ];
    default:
      return state;
  }
};

const INIT_CHANGE_BALANCE_MODAL_OPEN_STATE: ChangeBalanceModalOpenState = false;

export const changeBalanceModalOpenReducer = (
  state: ChangeBalanceModalOpenState = INIT_CHANGE_BALANCE_MODAL_OPEN_STATE,
  action: ChangeBalanceModalOpenAction
) => {
  switch (action.type) {
    case SET_CHANGE_BALANCE_MODAL_OPEN:
      return action.payload;
    default:
      return state;
  }
};

const INIT_EXPORT_ADDRESS_MODAL_OPEN_STATE: ExportAddressModalOpenState = false;

export const exportAddressModalOpenReducer = (
  state: ExportAddressModalOpenState = INIT_EXPORT_ADDRESS_MODAL_OPEN_STATE,
  action: ExportAddressModalOpenAction
) => {
  switch (action.type) {
    case SET_EXPORT_ADDRESS_MODAL_OPEN:
      return action.payload;
    default:
      return state;
  }
};
