import {
  SET_ACTIVE_CUSTOMER_DETAILS_ID,
  CLEAR_ACTIVE_CUSTOMER_DETAILS_ID,
  SHOW_STORED_CUSTOMER_DETAILS,
  FETCH_CUSTOMER_DETAILS_SUCCESS,
  FETCH_CUSTOMER_DETAILS_ERROR,
} from "../actions";

import { CustomerDetailsState, CustomerDetailsAction } from "./model";

const INIT_CUSTOMER_DETAILS_STATE: CustomerDetailsState = {
  showCustomerDetailsId: null,
  results: [],
  loading: false,
  error: "",
};

const customerDetailsReducer = (
  state: CustomerDetailsState = INIT_CUSTOMER_DETAILS_STATE,
  action: CustomerDetailsAction
) => {
  switch (action.type) {
    case SET_ACTIVE_CUSTOMER_DETAILS_ID:
      return {
        ...state,
        showCustomerDetailsId: action.payload,
        loading: true,
        error: "",
      };
    case CLEAR_ACTIVE_CUSTOMER_DETAILS_ID:
      return {
        ...state,
        showCustomerDetailsId: null,
        loading: false,
        error: "",
      };
    case SHOW_STORED_CUSTOMER_DETAILS:
      return { ...state, loading: false, error: "" };
    case FETCH_CUSTOMER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        results: [...state.results, action.payload],
      };

    case FETCH_CUSTOMER_DETAILS_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default customerDetailsReducer;
