import { DropdownOptionsState, DropdownOptionsAction } from "./model";
import {
  FETCH_DROPDOWN_OPTIONS,
  FETCH_DROPDOWN_OPTIONS_ERROR,
  FETCH_DROPDOWN_OPTIONS_SUCCESS,
} from "../actions";

const INIT_DROPDOWN_OPTIONS_STATE: DropdownOptionsState = {
  error: "",
  loading: false,
  data: null,
};

export const dropdownOptionsReducer = (
  state: DropdownOptionsState = INIT_DROPDOWN_OPTIONS_STATE,
  action: DropdownOptionsAction
) => {
  switch (action.type) {
    case FETCH_DROPDOWN_OPTIONS:
      return { data: null, loading: true, error: "" };
    case FETCH_DROPDOWN_OPTIONS_SUCCESS:
      return {
        loading: false,
        error: "",
        data: JSON.parse(JSON.stringify(action.payload)),
      };
    case FETCH_DROPDOWN_OPTIONS_ERROR:
      return { data: null, loading: false, error: action.payload };
    default:
      return state;
  }
};
