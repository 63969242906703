import { capitalizeFirstChar } from "./utils";

export default {
  networkError:
    "Encountered network error. Please check your internet connection.",
  relogin: "Session has ended, please login again",
  login: "Please login to the application",
  newPasswordFail: "Please fill in a valid new password",
  noConfirmPassword: "Please confirm your new password",
  forgotPasswordSuccess:
    "Instructions to reset your password have been sent to the email provided",
  confirmNotMatchNewPassword: "Confirm password does not match new password",
  noOldPassword: "Please fill in your old password",
  oldSameAsNewPassword: "New password should not be the same as old password",
  changePasswordSuccess:
    "Password has been successfully changed. Please relogin with your new password.",
  useResetLinkFromEmail: "Please use the reset link from the email sent to you",
  mobilePasswordResetSuccess:
    "Password has been successfully reset. You may now close this browser and login to the mobile application using your new password.",
  webPasswordResetSuccess:
    "Password has been successfully reset. Please log in with your new password.",
  createAccountSuccess: (role: string) =>
    `${capitalizeFirstChar(role)} account has been successfully created`,
  createAccountError:
    "Something went wrong, account was not created. Please try again later",
  editAccountSuccess: (role: string, email: string) =>
    `${capitalizeFirstChar(
      role
    )} account for ${email} has been successfully updated`,
  editAccountError:
    "Something went wrong, account was not updated. Please try again later",
  requiredInput: "Please fill in this field",
  requiredOption: "Please choose an option",
  requiredEmail: "Please enter an email address",
  invalidEmail: "Invalid email address",
  invalidDateFormat:
    "Invalid date format, expected DD/MM/YYYY format (e.g. 31/12/2030)",
  userRetrievalFail:
    "Failed to retrieve existing user information, user cannot be edited.",
  editNoUserSelected:
    "No user was selected to be edited. Please edit a user from Manage Users page.",
  invalidCardExpiryDateFormat:
    "cardExpiryDate format is not valid, expected format is DD/MM/YYYY (e.g. 31/12/2030)",
  loadAPIError:
    "Something went wrong, existing information could not be loaded. Please try again later",
  submitAPIError:
    "Something went wrong, information could not be sent/processed by the server. Please try again later.",
  saveConfigSuccess:
    "Configurations were successfully saved. Any changes will take effect immediately.",
  fetchExportError:
    "Something went wrong, information could not be exported. Please try again later.",
  startDateAfterEndDate: (functionality: string) =>
    `Start Date of ${functionality} cannot be later than End Date`,
  exceedDateRange: (maxPeriod: string, functionality: string) =>
    `For ${functionality}, number of days between Start Date and End Date cannot exceed ${maxPeriod}`,
  loadingExport:
    "Please wait... File download will start automatically once ready.",
  exportSuccess: "File download should start right now",
  defaultError: "Something went wrong, please try again later.",
  invalidBirthYear: "Year selected is not a valid birth year.",
  consentRequired:
    "You can only proceed if you agree with the Terms and Conditions and Privacy Policy.",
  completeRecaptcha: "Please complete the Google Recaptcha before submitting.",
  someErrorsInForm: "There are some errors in the form",
  loadOptionsFail:
    "Unable to load dropdown options for this form, please try again later or contact IT support.",
  requireAtLeastOne: "Please select at least one option",
  disableSpecificBrandsIfAll:
    'If Admin manages all brands, set "Admin Manages Specific Brands" to "No"',
  addAdminSuccess: "Admin user has been successfully added to the system.",
  invalidCustomerFamily:
    'Invalid customer selected, please select a valid customer from "Manage Customers" page to edit instead',
  cardCustomerMissingRFID:
    "Missing Card RFID for this customer. Card is not allowed to be removed. Either replace with a new Card RFID or change Card Status to Deactivated.",
  invalidOutlet:
    'Invalid outlet selected, please select a valid outlet from "Manage Users" page instead',
  invalidAdminUser:
    'Invalid admin user selected, please select a valid admin user from "Manage Users" page instead',
  passwordResetKioskRelogin:
    "Upon successful password reset, Kiosk will be immediately logged out of kiosk app, and will have to use this new password set by you to relogin.",
  inactiveOutlet:
    "This outlet has been disabled in the system and its kiosk account can no longer by created or modified.",
  amountIsRequired:
    "Amount to top-up/deduct needs to be filled in and should be more than 0.",
  changeBalanceLoading:
    "Balance(s) are being changed... Please wait a moment...",
  changeBalanceSuccess: "Card balance(s) have been successfully changed.",
  invalidMobileLength: "Mobile number must be 8 digits in length",
  invalidMobileNumber: 'Mobile number starts with "8" or "9" character',
  adminReasonRequired: "Please enter a reason for changing balance",
  failChangeBalanceWithNonCardCustomer:
    "Please uncheck non-card customers first before attempting to change card balance.",
  exportAddressSuccess: ({
    hasChangedRegistrationStatus,
  }: {
    hasChangedRegistrationStatus: boolean;
  }) =>
    `${
      hasChangedRegistrationStatus
        ? "Registration status of customers has been successfully changed. "
        : ""
    }File download should start right now.`,
  deleteUnlinkedCardLoading: "Deleting card... please wait...",
  deleteUnlinkedCardSuccess:
    "Successfully deleted unlinked card from the system.",
};
