import {
  all,
  call,
  fork,
  put,
  takeEvery,
  SagaReturnType,
} from "redux-saga/effects";

import { FETCH_DASHBOARD_DATA_ANALYTICS } from "../actions";

import {
  fetchDashboardDataAnalyticsError,
  fetchDashboardDataAnalyticsSuccess,
} from "./actions";

import { fetchDashboardAPI } from "../../APIs";

import { DashboardDataAnalyticsResponse, DashboardAnalysedData } from "./model";
import messageStrings from "../../helpers/messageStrings";

const fetchDashboardDataAnalyticsAsync =
  async (): Promise<DashboardDataAnalyticsResponse> => {
    try {
      const response = await fetchDashboardAPI();
      return response.data;
    } catch (err) {
      throw err;
    }
  };

function* handleFetchDashboardDataAnalytics() {
  try {
    const response: SagaReturnType<typeof fetchDashboardDataAnalyticsAsync> =
      yield call(fetchDashboardDataAnalyticsAsync);

    const dataAnalyticsByMode = response.data.reduce(
      (acc: DashboardAnalysedData, cur) => {
        if (cur.type === "organization") {
          acc.organization = cur.jsonData;
          acc.timestamp = cur.createdAt;
        } else {
          acc.brands.push({
            refId: cur.refId,
            brandId: cur.refId.split("_")[0],
            data: cur.jsonData,
          });
        }

        return acc;
      },
      { organization: null, brands: [], timestamp: null }
    );

    yield put(fetchDashboardDataAnalyticsSuccess(dataAnalyticsByMode));
  } catch (error) {
    yield put(fetchDashboardDataAnalyticsError(messageStrings.defaultError));
  }
}

export function* watchFetchDashboardDataAnalytics() {
  yield takeEvery(
    FETCH_DASHBOARD_DATA_ANALYTICS,
    handleFetchDashboardDataAnalytics
  );
}

export default function* rootSaga() {
  yield all([fork(watchFetchDashboardDataAnalytics)]);
}
