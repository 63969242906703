import { DashboardAnalysedData, DashboardTab } from "./model";

import {
  FETCH_DASHBOARD_DATA_ANALYTICS,
  FETCH_DASHBOARD_DATA_ANALYTICS_SUCCESS,
  FETCH_DASHBOARD_DATA_ANALYTICS_ERROR,
  SET_DASHBOARD_TAB,
  SET_DASHBOARD_BRAND_OPTION,
} from "../actions";

export const setDashboardTab = (tabValue: DashboardTab) => {
  return { type: SET_DASHBOARD_TAB, payload: tabValue };
};

export const setDashboardBrandOption = (brandId: string) => {
  return { type: SET_DASHBOARD_BRAND_OPTION, payload: brandId };
};
export const fetchDashboardDataAnalytics = () => {
  return { type: FETCH_DASHBOARD_DATA_ANALYTICS };
};

export const fetchDashboardDataAnalyticsSuccess = (
  responseData: DashboardAnalysedData
) => {
  return {
    type: FETCH_DASHBOARD_DATA_ANALYTICS_SUCCESS,
    payload: responseData,
  };
};

export const fetchDashboardDataAnalyticsError = (message: string) => {
  return {
    type: FETCH_DASHBOARD_DATA_ANALYTICS_ERROR,
    payload: message,
  };
};
