export type ReportFilter = Partial<ReportFilterState>;

export interface ReportFilterAction {
  type: string;
  payload: ReportFilter;
}

export interface ReportFilterState {
  reportType: ReportType;
  brandId: string;
  outletId: string;
  startDate: string;
  endDate: string;
}

export enum ReportType {
  TOP_UP_SUMMARY = "top-up-summary",
  CARD_REGISTRATION_FEE = "card-registration-fee",
}

export interface CardRegistrationFeeItem {
  id: number;
  paidDate: Date;
  amount: number;
  customer?: {
    id: number;
    firstName: string;
    lastName: string;
    phoneDialingCode?: string;
    phoneNumber?: string;
    email?: string;
    isPrimary?: boolean;
  };
  outlet?: {
    outletId: string;
    name: string;
  };
  card: {
    id: number;
    RFID: string;
  };
  receiptId?: string;
  platformFee?: number;
  platformPaymentId?: string;
  paymentMethod?: string;
}

export interface TopUpSummaryItem {
  summaryDate: string;
  totalAmount: number;
  totalPaidAmount: number;
}
