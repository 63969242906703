import {
  all,
  call,
  fork,
  put,
  takeEvery,
  SagaReturnType,
} from "redux-saga/effects";

import { FETCH_TRANSACTION_LISTING } from "../actions";

import {
  fetchTransactionListingSuccess,
  fetchTransactionListingError,
} from "./actions";

import { fetchTransactionListAPI } from "../../APIs";

import { TransactionListingReq, TransactionListingResponse } from "./model";

import messageStrings from "../../helpers/messageStrings";

const fetchTransactionsAsync = async (
  filters: TransactionListingReq
): Promise<TransactionListingResponse> => {
  try {
    const response = await fetchTransactionListAPI(filters);
    return response.data;
  } catch (err) {
    throw err;
  }
};

function* handleFetchTransactions({
  payload,
}: {
  type: typeof FETCH_TRANSACTION_LISTING;
  payload: TransactionListingReq;
}) {
  try {
    const results: SagaReturnType<typeof fetchTransactionsAsync> = yield call(
      fetchTransactionsAsync,
      payload
    );

    yield put(fetchTransactionListingSuccess(results));
  } catch (error: any) {
    yield put(
      fetchTransactionListingError(error.message || messageStrings.defaultError)
    );
  }
}

export function* watchFetchTransactionListing() {
  yield takeEvery(FETCH_TRANSACTION_LISTING, handleFetchTransactions);
}

export default function* rootSaga() {
  yield all([fork(watchFetchTransactionListing)]);
}
