import { TransactionFilter, TransactionListing } from "./model";

import {
  SET_TRANSACTION_FILTER,
  FETCH_TRANSACTION_LISTING,
  FETCH_TRANSACTION_LISTING_SUCCESS,
  FETCH_TRANSACTION_LISTING_ERROR,
} from "../actions";

export const setTransactionFilter = (
  newFiltersKeyValues: TransactionFilter
) => {
  return { type: SET_TRANSACTION_FILTER, payload: newFiltersKeyValues };
};

export const fetchTransactionListing = (filters: { [key: string]: any }) => {
  return { type: FETCH_TRANSACTION_LISTING, payload: filters };
};

export const fetchTransactionListingSuccess = (results: TransactionListing) => {
  return { type: FETCH_TRANSACTION_LISTING_SUCCESS, payload: results };
};

export const fetchTransactionListingError = (message: string) => {
  return { type: FETCH_TRANSACTION_LISTING_ERROR, payload: message };
};
