export enum AccessRights {
  VIEW_BRAND_OUTLET_DATA_ANALYTICS = "viewBrandOutletDataAnalytics",
  VIEW_CUSTOMER = "viewCustomer",
  ADD_EDIT_CUSTOMER = "addEditCustomer",
  CHANGE_CARD_BALANCE = "changeCardBalance",
  EXPORT_CUSTOMER_INFO = "exportCustomerInfo",
  VIEW_TRANSACTION_HISTORY = "viewTransactionHistory",
  VIEW_CARD_BALANCE_CHANGE_HISTORY = "viewCardBalanceChangeHistory",
  VIEW_USER = "viewUser",
  ADD_EDIT_ADMIN_USER = "addEditAdminUser",
  ADD_EDIT_MERCHANT_USER = "addEditMerchantUser",
  VIEW_UNLINKED_CARD = "viewUnlinkedCard",
  ADD_DELETE_UNLINKED_CARD = "addDeleteUnlinkedCard",
  EXPORT_OTHER_FINANCE_REPORTS = "exportOtherFinanceReports",
}

export interface AccessRightModel {
  id: number;
  name: string;
  [AccessRights.VIEW_BRAND_OUTLET_DATA_ANALYTICS]: boolean;
  [AccessRights.VIEW_CUSTOMER]: boolean;
  [AccessRights.ADD_EDIT_CUSTOMER]: boolean;
  [AccessRights.CHANGE_CARD_BALANCE]: boolean;
  [AccessRights.EXPORT_CUSTOMER_INFO]: boolean;
  [AccessRights.VIEW_TRANSACTION_HISTORY]: boolean;
  [AccessRights.VIEW_CARD_BALANCE_CHANGE_HISTORY]: boolean;
  [AccessRights.VIEW_USER]: boolean;
  [AccessRights.ADD_EDIT_ADMIN_USER]: boolean;
  [AccessRights.ADD_EDIT_MERCHANT_USER]: boolean;
  [AccessRights.VIEW_UNLINKED_CARD]: boolean;
  [AccessRights.ADD_DELETE_UNLINKED_CARD]: boolean;
  [AccessRights.EXPORT_OTHER_FINANCE_REPORTS]: boolean;
}

export type BrandsModel = { brandId: string; name: string }[] | undefined;

export interface CurrentUserState {
  id: number;
  firstName: string;
  lastName: string;
  role?: number;
  email?: string;
  accessRights?: AccessRightModel;
  brands?: BrandsModel;
}

export interface LoginResponse {
  user?: CurrentUserState;
  jwtExpirationDate?: Date;
  message?: string;
}

export interface FetchUserDetailsResponse extends CurrentUserState {
  message?: string;
}

export interface ForgotPasswordResponse {
  message?: string;
}

export interface ResetPasswordResponse {
  message?: string;
}

export interface LogoutResponse {
  message?: string;
}

export interface AuthState {
  currentUser: CurrentUserState | null;
  loading: boolean;
  error: string;
  isForceLogoutOn: boolean;
}

export interface AuthAction {
  type: string;
  payload: LoginResponse;
}

export interface AuthError {
  message: string;
}

export interface ChangePasswordResponse {
  message?: string;
}

export interface ChangePasswordState {
  loading: boolean;
  error: string;
}

export interface ChangePasswordAction {
  type: string;
  payload: ChangePasswordResponse;
}
