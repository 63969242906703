/* eslint-disable no-unused-vars */
import { all } from "redux-saga/effects";
import authSagas from "./auth/saga";
import dashboardSagas from "./dashboardModule/saga";

import customerSagas from "./customerModule/saga";
import unlinkedCardSagas from "./unlinkedCardModule/saga";
import transactionSagas from "./transactionModule/saga";
import balanceChangeSagas from "./balanceChangeModule/saga";
import userSagas from "./userModule/saga";
import dropdownOptionsSagas from "./dropdownOptions/saga";
import customerDetailsSagas from "./customerDetails/saga";

export default function* rootSaga(getState: any) {
  yield all([
    authSagas(),
    dashboardSagas(),
    customerSagas(),
    unlinkedCardSagas(),
    transactionSagas(),
    balanceChangeSagas(),
    userSagas(),
    dropdownOptionsSagas(),
    customerDetailsSagas(),
  ]);
}
