import {
  all,
  call,
  fork,
  put,
  takeEvery,
  SagaReturnType,
} from "redux-saga/effects";

import { FETCH_USER_LISTING } from "../actions";

import { fetchUserListingSuccess, fetchUserListingError } from "./actions";

import { fetchUserListAPI } from "../../APIs";

import { UserFilterState, UserListingResponse } from "./model";

import messageStrings from "../../helpers/messageStrings";

const fetchUsersAsync = async (
  filters: UserFilterState
): Promise<UserListingResponse> => {
  try {
    const response = await fetchUserListAPI(filters);
    return response.data;
  } catch (err) {
    throw err;
  }
};

function* handleFetchUsers({
  payload,
}: {
  type: typeof FETCH_USER_LISTING;
  payload: UserFilterState;
}) {
  try {
    const results: SagaReturnType<typeof fetchUsersAsync> = yield call(
      fetchUsersAsync,
      payload
    );

    yield put(fetchUserListingSuccess(results));
  } catch (error: any) {
    yield put(
      fetchUserListingError(error.message || messageStrings.defaultError)
    );
  }
}

export function* watchFetchUserListing() {
  yield takeEvery(FETCH_USER_LISTING, handleFetchUsers);
}

export default function* rootSaga() {
  yield all([fork(watchFetchUserListing)]);
}
