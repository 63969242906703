import { UserFilter, UserListing } from "./model";

import {
  SET_USER_FILTER,
  FETCH_USER_LISTING,
  FETCH_USER_LISTING_SUCCESS,
  FETCH_USER_LISTING_ERROR,
} from "../actions";

export const setUserFilter = (newFiltersKeyValues: UserFilter) => {
  return { type: SET_USER_FILTER, payload: newFiltersKeyValues };
};

export const fetchUserListing = (filters: { [key: string]: any }) => {
  return { type: FETCH_USER_LISTING, payload: filters };
};

export const fetchUserListingSuccess = (results: UserListing) => {
  return { type: FETCH_USER_LISTING_SUCCESS, payload: results };
};

export const fetchUserListingError = (message: string) => {
  return { type: FETCH_USER_LISTING_ERROR, payload: message };
};
