import {
  all,
  call,
  fork,
  put,
  takeEvery,
  SagaReturnType,
} from "redux-saga/effects";

import { FETCH_UNLINKED_CARD_LISTING } from "../actions";

import {
  fetchUnlinkedCardListingSuccess,
  fetchUnlinkedCardListingError,
} from "./actions";

import { fetchUnlinkedCardListAPI } from "../../APIs";

import { UnlinkedCardListingResponse } from "./model";

import messageStrings from "../../helpers/messageStrings";

const fetchUnlinkedCardsAsync =
  async (): Promise<UnlinkedCardListingResponse> => {
    try {
      const response = await fetchUnlinkedCardListAPI();
      return response.data;
    } catch (err) {
      throw err;
    }
  };

function* handleFetchUnlinkedCards(action: {
  type: typeof FETCH_UNLINKED_CARD_LISTING;
}) {
  try {
    const results: SagaReturnType<typeof fetchUnlinkedCardsAsync> = yield call(
      fetchUnlinkedCardsAsync
    );

    yield put(fetchUnlinkedCardListingSuccess(results));
  } catch (error: any) {
    yield put(
      fetchUnlinkedCardListingError(
        error.message || messageStrings.defaultError
      )
    );
  }
}

export function* watchFetchUnlinkedCardListing() {
  yield takeEvery(FETCH_UNLINKED_CARD_LISTING, handleFetchUnlinkedCards);
}

export default function* rootSaga() {
  yield all([fork(watchFetchUnlinkedCardListing)]);
}
